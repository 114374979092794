<template>
  <div :style="{ display: 'inline' }" />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'PlaidLink',
  props: {
    webhook: {
      type: String,
      required: false,
      default: ''
    },
    onLoad: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    onSuccess: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    onExit: {
      type: Function,
      required: false,
      default: () => {
        
      }
    },
    onEvent: {
      type: Function,
      required: false,
      default: () => {
      }
    }
  },
  data() {
    return {
      plaid: null,
      plaidInstance: null
    };
  },
  computed: {
    ...mapGetters('fund', ['getPlaidToken']),
    ...mapGetters('account', ['getSelectedAccount'])
  },
  methods: {
    ...mapActions('fund', ['fetchPlaidToken']),
    openLink(e) {
      if (e) e.preventDefault();
      if (this.plaidInstance == null) {
        this.configurePlaidInstance();
      } else {
        this.plaidInstance.open();
      }
    },
    configurePlaidInstance() {
      if (this.getPlaidToken != null) {
        let self = this;
        this.plaid = window.Plaid;
        this.plaidInstance = this.plaid.create({
          token: this.getPlaidToken.linkToken,
          onLoad: function () {
            // Optional, called when Link loads
            self.onLoad();
          },
          onSuccess: function (public_token, metadata) {
            // Send the public_token to your app server.
            // The metadata object contains info about the institution the
            // user selected and the account ID or IDs, if the
            // Select Account view is enabled.
            self.onSuccess(public_token, metadata);
          },
          onExit: function (err, metadata) {
            // The user exited the Link flow.
            if (err != null) {
              // The user encountered a Plaid API error prior to exiting.
            }
            // metadata contains information about the institution
            // that the user selected and the most recent API request IDs.
            // Storing this information can be helpful for support.
            self.onExit(err, metadata);
          },
          onEvent: function (eventName, metadata) {
            // Optionally capture Link flow events, streamed through
            // this callback as your users connect an Item to Plaid.
            // For example:
            // eventName = "TRANSITION_VIEW"
            // metadata  = {
            //   link_session_id: "123-abc",
            //   mfa_type:        "questions",
            //   timestamp:       "2017-09-14T14:42:19.350Z",
            //   view_name:       "MFA",
            // }
            self.onEvent(eventName, metadata);
          }
        });

        this.plaidInstance.open();
      } else {
        this.getToken();
      }
    },
    getToken() {
      const loader = this.showLoader();
      this.fetchPlaidToken(this.getSelectedAccount.id)
        .then(() => {
          setTimeout(() => {
            this.configurePlaidInstance();
          }, 0);
        })
        .catch((e) => {
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    }
  },
  mounted() {
    let scriptExists = !!document.getElementById('plaid-link-script');
    if (scriptExists) return;
    let linkScript = document.createElement('script');
    linkScript.async = true;
    linkScript.setAttribute('id', 'plaid-link-script');
    linkScript.setAttribute(
      'src',
      'https://cdn.plaid.com/link/v2/stable/link-initialize.js'
    );
    document.head.appendChild(linkScript);
    linkScript.onload = () => {
    };
  },
  beforeDestroy() {
    if (this.plaidInstance) {
      this.plaidInstance.destroy();
    }
  }
};
</script>
